import { Row, Col, Icon, Cell, CellGroup, Toast } from 'vant';
import { USER_GETINFO } from '../../http/api';
import { ref } from 'vue';
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },

  setup() {
    const userinfo = ref(null);

    const goVip = () => {
      window.location.href = "/#/vip";
    };

    const income = () => {
      window.location.href = "/#/income";
    };

    USER_GETINFO().then(res => {
      Toast.clear();
      userinfo.value = res.data.data.user_info;
    });
    return {
      goVip,
      userinfo,
      income
    };
  }

};